.rotate {
  background: black;
  height: 100vh;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.phone {
  height: 50px;
  width: 100px;
  border: 3px solid white;
  border-radius: 10px;
  animation: rotate 1.5s ease-in-out infinite alternate;
  /* display: none; */
}

.message {
  color: white;
  font-size: 1em;
  margin-top: 40px;
  /* display: none; */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

@media only screen and (max-device-width: 812px) and (orientation: landscape) {
  .rotate {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .fullpage-wrapper {
    display: none;
  }

  .header {
    display: none;
  }
}
