@font-face {
  font-family: "FuturaCond";
  src: url("/fonts/futura-condensed-medium.otf") format("opentype");
}

@font-face {
  font-family: "FuturaPT";
  src: url("/fonts/FuturaPTMedium.otf") format("opentype");
}

@font-face {
  font-family: "FuturaPTCondBold";
  src: url("/fonts/FuturaPTCondBold.otf") format("opentype");
}

@font-face {
  font-family: "FuturaPTBold";
  src: url("/fonts/FuturaPTBold.otf") format("opentype");
}

@font-face {
  font-family: "FuturaPTLight";
  src: url("/fonts/FuturaPTLight.otf") format("opentype");
}

.appLoading {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 99999;
}

.header {
  padding: 30px 65px 30px 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.menuHeader {
  padding: 30px 50px 30px 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.menuSection {
  width: 100vw;
  height: 100vh;
  background-color: #1f2129;
  overflow: hidden;
}

.btnMenu {
  background-color: rgba(255, 255, 255, 0.1);
  width: 120px;
  height: 15px;
  margin-top: 12px;
}

.btnMenu2 {
  border: none !important;
  cursor: pointer !important;
  background-color: transparent;
  width: 120px;
  height: 50px;
  margin-top: -25px;
}

.onyxTitle {
  margin: 0;
  align-self: center;
  color: #fff;
  font-family: FuturaPT;
  font-size: 1.1rem;
}

.videoBg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  object-fit: cover;
  -webkit-border-radius: 1px; /* Chrome fix for object-fit cover not cropping the video */
}

.videoBgContent {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.seeMoreBtn {
  color: white;
  font-size: 18px;
  background: rgb(7, 8, 11);
  background: linear-gradient(180deg, transparent 50%, rgba(26, 24, 28, 1) 50%);
  border: none;
  padding: 5px 15px 5px 15px;
  margin-bottom: 50px;
  font-family: FuturaPT;
}

.section01 {
  width: 100%;
  height: 100%;
  background-color: #424242;
}

.section01Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section02 {
  width: 100%;
  height: 100%;
  background: url("/images/section02-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}

.section02Container {
  width: 100vw;
  max-width: 1920px;
}

.section02ContainerSmall {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.section02Title {
  color: white;
  font-size: 3.2rem;
  line-height: 3.5rem;
  letter-spacing: 0.35rem;
  font-family: FuturaPTBold;
}

.section02Subtitle {
  margin-top: 32px;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.21rem;
  font-family: FuturaPTLight;
}

.section03 {
  width: 100%;
  height: 100%;
}

.section03Container {
  width: 100vw;
  max-width: 1920px;
}

.section03Content {
  background: url("/images/section03-overlay.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.theWay {
  color: white;
  font-size: 3.9rem;
  line-height: 3.7rem;
  margin-top: -32px;
  margin-bottom: 0;
  text-align: right;
  display: inline-block;
  font-family: FuturaPTCondBold;
}

.section04 {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.section04Background {
  background: url("/images/section04-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.section04Container {
  width: 100vw;
  max-width: 1920px;
  padding: 45px 3.5vw 0 3.5vw;
}

.section04Title {
  color: white;
  font-size: 4.1rem;
  line-height: 3.6rem;
  font-family: FuturaPTBold;
}

.section04Subtitle {
  margin-top: 15px;
  color: #353741;
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-family: FuturaPT;
}

.counterTitle {
  color: white;
  font-size: 2.7rem;
  line-height: 2.4rem;
  margin-bottom: 0;
  margin-top: 25px;
  font-family: FuturaPTCondBold;
}

.counterSubtitle {
  color: #666873;
  font-size: 1.7rem;
  line-height: 1.7rem;
  margin-top: 0px;
  margin-bottom: 0;
  font-family: FuturaPTCondBold;
}

.section05 {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.section05Background {
  background: rgb(32, 35, 42);
  background-image: url("/images/section05-bg.png");
  background-image: url("/images/section05-bg.png"),
    linear-gradient(180deg, rgba(32, 35, 42, 1) 0%, rgba(1, 0, 2, 1) 80%);
  background-repeat: no-repeat;
  background-position: center bottom;
}

.section05Container {
  width: 100vw;
  max-width: 1920px;
  padding: 70px 3.5vw 0 3.5vw;
}

.section05Title {
  color: white;
  font-size: 4.3rem;
  line-height: 3.5rem;
  letter-spacing: 0.25rem;
  font-family: FuturaPTBold;
}

.section05Subtitle {
  margin-top: 32px;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.35rem;
  font-family: FuturaPT;
}

.section05Name {
  text-align: right;
  font-size: 6rem;
  color: #33373e;
  opacity: 0.3;
  line-height: 6.2rem;
  margin-bottom: 30px;
  font-family: FuturaPTBold;
}

.awards {
  margin-top: 20px;
  text-align: center;
  float: right;
}

.awards > img {
  margin: 10px 0px 20px 10px;
  max-width: 33.33%;
}

.section06 {
  width: 100%;
  height: 100%;
}

.section06Container {
  width: 100vw;
  height: 100%;
  max-width: 1920px;
  padding: 70px 3.5vw 0 3.5vw;
}

.section06Title {
  color: white;
  font-size: 5.6rem;
  font-family: FuturaPTBold;
  line-height: 4.2rem;
  word-break: break-all;
}

.section06Subtitle {
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.5rem;
  font-family: FuturaPT;
  margin: 15px 80px 50px 0px;
}

.section06List {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.section06List > li {
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.3rem;
  text-align: justify;
  margin: 35px 120px 0px 0px;
  font-family: FuturaPTLight;
}

.networks {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.networks > img {
  margin: 0 10px;
  max-width: 20%;
}

.section07 {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  /*background: #0d0b0d;*/
  background: black;
}

.section07Container {
  width: 100vw;
  max-width: 1920px;
  padding: 70px 3.5vw 0 3.5vw;
}

.contactInfo {
  color: white;
  font-size: 1.8rem;
  line-height: 3.2rem;
  letter-spacing: 0.1rem;
  margin: 50px 5px;
  word-break: break-all;
  font-family: FuturaPT;
}

.sendButton {
  background-color: #121112;
  color: white;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  padding: 10px 30px 10px 30px;
  border: 0;
  border-radius: 5px;
  font-family: FuturaPT;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

.modal-content {
  border: none !important;
  background-color: #151315 !important;
}

.modal-header > .close {
  color: white !important;
}

.inputContact {
  width: 100%;
  padding: 10px 20px;
  background-color: #121112;
  border: none;
  color: white;
  margin: 10px 0px;
  font-family: FuturaPTLight;
}

div[bsclass="menu-modal"] {
  margin: 0 !important;
}

div[bsclass="menu-modal"] > .modal-content {
  width: 100vw;
  height: 100vh;
  background: url("/images/menu-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
. div[bsclass="menu-modal"] > .modal-content > .modal-header {
  padding: 30px 50px;
  z-index: 99999999;
}

div[bsclass="menu-modal"] > .modal-content > .modal-body {
  display: flex;
  justify-content: space-between;
}

.menuItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menuItems > button,
.menuItems > a {
  padding: 10px 5px 10px 20px;
  color: white;
  font-size: 2.9rem;
  text-align: left;
  font-family: FuturaPTBold;
  letter-spacing: 0.1rem;
  line-height: 3.2rem;
  border: none;
  background-color: transparent;
  text-decoration: none;
}

.menuItems > a:hover,
.menuItemRow > a:active {
  text-decoration: none;
  color: white;
}

.menuItemTitle {
  color: white;
  font-size: 2.3rem;
  font-family: FuturaPTBold;
  text-align: center;
}

.menuItemSubtitle {
  color: white;
  font-size: 1.4rem;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
  margin: 50px 5px;
  word-break: break-all;
  text-align: center;
  font-family: FuturaPT;
}

.menuItemRow {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 100%;
}

.floatingDot {
  width: 1rem;
  height: 1rem;
  background-color: #e130e0;
  border-radius: 50%;
  position: absolute;
  z-index: 999;
}

.finalDot {
  color: #e130e0;
  font-size: 3.2rem;
  padding-left: 5px;
}

.lottieSection01Inner {
  margin-left: 25px;
  margin-top: 280px;
}

.section04Video {
  width: 90%;
  text-align: center;
  z-index: 2;
}

/*
.section04Video:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 5%;
  width: 90%;
  height: 99.4%;
  border-radius: 15px;
  background: url("/images/gradientVideoOverlay.png");
  background-repeat: no-repeat;
  background-size: cover;
}
*/

.btnAction {
  color: #e130e0;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #e130e0;
  font-size: 18px;
  padding: 5px 15px 5px 15px;
  margin: 65px 0 25px 0;
  font-family: FuturaPT;
}

.btnAction:hover,
.btnAction:active {
  color: white;
  background-color: #e130e0;
}

.modal-video {
  position: relative !important;
}

.modal-video-inner {
  padding-top: 60px !important;
}

.videoContact {
  position: fixed;
  object-fit: cover;
  -webkit-border-radius: 1px; /* Chrome fix for object-fit cover not cropping the video */
}

.sliderTitle {
  position: absolute;
  font-family: FuturaPT;
  font-size: 1.5rem;
  color: white;
  padding: 0px 8vw 0px 8vw;
}

/* iPhone size screens or bigger */
@media (min-width: 641px) {
  .menuItems {
    height: 100%;
  }
}

/* HD Ready or smaller */
@media (max-width: 1440px) {
  .section06Title {
    font-size: 4.4rem;
    line-height: 3.6rem;
  }

  .section06Subtitle {
    font-size: 1.34rem;
  }

  .section06List > li {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin: 25px 120px 0px 0px;
  }

  .networks {
    margin-top: 25px;
  }

  .section04Title {
    font-size: 3.8rem;
    line-height: 3.4rem;
  }

  .section05Name {
    font-size: 5.1rem;
    line-height: 4.8rem;
  }

  .counterSubtitle {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

/* 720p or smaller */
@media (max-width: 1280px) {
}

/* iPhone size screens or smaller */
@media (max-width: 641px) {
  .menuItems > button,
  .menuItems > a {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .btnMenu {
    background-color: transparent;
    width: 25px;
    margin-top: -2px;
  }

  .btnMenu2 {
    margin-top: 0px;
  }

  .section04Container {
    width: 100vw;
    max-width: 1920px;
  }

  .btnMenu > img {
    margin-top: 0 !important;
    margin-left: 0 !important;
  }

  .lottieSection01Inner {
    margin-left: 0px;
    margin-top: 0px;
  }

  .lottieSection01 {
    width: 100vw;
  }

  .section04 {
    padding: 10px 3.5vw 0 3.5vw;
  }

  .section04Container {
    padding-top: 0;
  }

  .section04Title {
    font-size: 2.3rem;
    line-height: 2.1rem;
    margin-top: 0px;
  }

  .section05Container {
    padding: 0px 20px 10px 20px;
  }

  .section05Title {
    font-size: 2.3rem;
    line-height: 1.9rem;
  }

  .section05Subtitle {
    margin-top: 15px;
    font-size: 1rem;
    line-height: 1.1rem;
  }

  .section06Title {
    font-size: 2.7rem;
    line-height: 2.3rem;
  }

  .section07Container {
    padding-top: 10px;
  }

  .contactInfo {
    font-size: 1.3rem;
    line-height: 2rem;
    letter-spacing: 0.1rem;
    margin: 35px 15px;
  }

  .menuItems {
    margin-top: 50px;
  }

  .awards {
    float: none;
    margin-top: 0;
  }

  .awards > img {
    max-width: 30%;
  }

  .theWay {
    margin-top: -15px;
    font-size: 2.5rem;
    line-height: 2.2rem;
  }
}
